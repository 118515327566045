export const locale = {
	lang: 'en',
	data: {
		"general": {
			"labelEmail": "Email",
			"labelUserProfile": "User profile",
			"labelIndicateType": "Indicate if you are a real estate agent, seller, or owner",
			"textVirtualReality": "Virtual reality",
			"textNew": "New",
			"textUsed": "Used",
			"labelRent": "for rent",
			"labelSale": "for sale"
		},
		"header": {
			"linkContact": "Contact us",
			"linkServices": "Services",
			"linkCreateAccount": "Create account",
			"linkUs": "Us",
			"linkLogin": "Log in",
			"linkLogout": "Log out",
			"linkMyAccount": "My account",
			"btnPublishProperty": "Publish property",
			"textConfirmCloseSession": "Are you sure you want to log out?",
			"textCloseSession": "Yes, sign out",
			"textNoBack": "No, go back"
		},
		"footer": {
			"text1": "Buy, rent or sell your property in an easy,",
			"text2": "smart and safe way.",
			"linkDevelopedBy1": "Developed with ",
			"linkDevelopedBy2": " by Seiza. All rights reserved.",
			"linkContact": "Contact us",
			"linkServices": "Services",
			"linkUs": "Us",
			"linkPublishProperty": "Publish property"
		},
		"home": {
			"textInBannerWeb1": "Search your ideal property",
			"textInBannerWeb2": "in Antigua Guatemala or",
			"textInBannerWeb3": "post one for free",
			"textInBannerMobile": "Search your ideal property or post one for free",
			"textRecentlyAdded": "Recently added",
			"textServicesOffered": "Services we offer you when publishing a property",
			"linkVirtualReality": "Virtual reality",
			"linkPhotography": "Photography",
			"linkLegalServices": "Legal services",
			"linkSellRentProperty": "We sell and rent your property",
			"linkManagementRentProperty": "Property Management",
			"linkDesign": "Interior Design",
			"textFirstBanner1": "Do you own a property? ",
			"textFirstBanner2": "we can sell or rent it for you",
			"textSecondBanner1": "We provide a complete service for the sale,",
			"textSecondBanner2": "purchase or rental of real estate",
			"textBannerProperties1": "New product from Luna Nueva Real Estate Portal",
			"textBannerProperties2": "Our exclusive properties allow you to manage your reservations in a simple and personalized way",
			"properties": "Luna Nueva Properties",
			"btnContactUs": "Contact us",
			"seeAll": "See everything"
		},
		"searchHeader": {
			"textProperties": "Properties",
			"textBuyingRenting": "Are you thinking of buying or renting? Check the properties we select for you"
		},
		"searchHeaderMobile": {
			"textSeeMoreFilter": "See more filters"
		},
		"sortPosts": {
			"textOrderBy": "Order by",
			"newest": "Newest",
			"lowerPrice": "Lower price",
			"higherPrice": "Higher price",
			"older": "Older"
		},
		"searchBar": {
			"labelSearchByLocation": "Search by location",
			"labelChooseOption": "Choose an option",
			"labelPropertyType": "Property type",
			"labelPriceRange": "Price range",
			"placeHolderSelectLocation": "Select location",
			"placeHolderLike": "What would you like?",
			"placeHolderChooseProperty": "Choose your property",
			"placeHolderChooseRange": "Choose your range price",
			"btnSearch": "Search",
			"btnSearchInMap": "Search in map",
			"textRent": "Rent",
			"textBuy": "Buy",
			"filters": "Filters",
			"clearFilters": "Clear Filters"
		},
		"rangePrice": {
			"from": "From",
			"to": "To"
		},

		"rentOptions": {
			"rent": "Rent Time",
			"one_month": "One Month",
			"three_months": "Three Months",
			"more_than_three_months": "More than three months",
			"one_year": "1 year"
		},
		"card": {
			"labelSeeMore": "See more",
			"labelBedroom": "Bd",
			"labelBedrooms": "Bds",
			"labelBathroom": "bathroom",
			"labelBathrooms": "bathrooms",
			"linkDoTour": "Tour 360º",
			"linkVirtualReality": "Virtual Reality",
			"textPostDeactivated": "Post deactivated",
			"textPostNoAvailable1": "We're sorry! This post isn't",
			"textPostNoAvailable2": "available, there may be sold",
			"textPostNoAvailable3": "or has been deactivated.",
			"textDelete": "Delete",
			"labelIn": "for",
			"labelHouse": "House",
			"labelApartment": "Apartment"
		},
		"cardMap": {
			"labelBathrooms": "bath"
		},
		"servicesForm": {
			"back": "Back",
			"labelContactForm": "Contact form",
			"labelFullName": "Full name",
			"labelTelephone": "Telephone",
			"textProfileType": "Landord / Buyer",
			"labelOptionToBeContacted": "Choose an option to be contacted",
			"labelSendMeACopy": "Send me a copy",
			"btnSend": "Send",
			"labelTour": "Tour 360º services",
			"labelVirtualReality": "Virtual reality services",
			"labelPhotography": "Photography services",
			"labelLegalServices": "Legal services",
			"labelSellProperty": "We sell your property",
			"labelRentProperty": "We rent your property",
			"labelPostProperty": "Post your property",
			"labelMessage": "Message",
			"labelManagement": "Property Management",
			"labelDesign": "Interior Design"
		},
		"fieldInput": {
			"textContinue": "Continue",
			"textLoading": "Loading...",
			"msgRequiredField": "Required field",
			"textInvalidEmailFormat": "Invalid email format",
			"textInvalidDpiFormat": "Invalid dpi format",
			"msgOnlyNumbers": "You must choose an area code and ensure that the number is correct.",
			"labelPassword": "Password",
			"labelRepeatPassword": "Repetir contraseña",
			"msgPasswordError": "Password must contain: 8 characters at least, 1 uppercase letter and 1 number.",
			"msgPasswordMatch": "Passwords must match",
			"msgPassword": "It must contain: 8 characters at least, one uppercase letter and one number.",
			"labelName": "Name",
			"lastName": "Last name",
			"labelDpiPassport": "DPI / Passport",
			"labelDocumentType": "Document type",
			"textSave": "Save",
			"textPassport": "Passport"
		},
		"register": {
			"textTitle": "Complete your data",
			"msgName": "Enter it as it appears in your document",
			"textTermAndConditions1": "I accept the ",
			"textTermAndConditions2": "Terms and Conditions ",
			"textTermAndConditions3": "and I authorize the use of my data according to the ",
			"textTermAndConditions4": "Privacy Statement",
			"ex": "Ex",
			"labelNationality": "Nationality",
			"msgAccessToEmail": "Make sure you have access to this email",
			"msgDpiPassport": "Select DPI or passport",
			"msgNationality": "Select nationality",
			"buttonText": "Close",
			"textHeader": "The request has been sent to your email",
			"textBody": "Go to your inbox to validate your email. If you can't find it, check your spam folder",
			"infoConditions": "You must accept the Terms and Conditions.",
			"infoError": "We couldn't register the user, please try again.",
			"infoFormInvalid": "To continue you must fill in all the form fields",
			"infoFormInvalidData": "Luna Nueva will not use your data without having previously consulted you",
			"infoErrorUserExist": "An account with this email already exists: ",
			"infoErrorUserExistData": "If you have an account in Luna Nueva real estate portal, you do not need to create a new one, log in with that email",
			"textButtonUserExist": "Use another email",
			"textButtonUserExistInitialSession": "Login",
			"textInformation": "Information"
		},
		"signIn": {
			"textWelcome": "Welcome! Enter your email address",
			"textRestorePwd": "Restore your password",
			"textCreateAccount": "Create account",
			"textIncorrectData": "Incorrect Data",
			"textInvalidCredentials": "Invalid Credentials"
		},
		"emailVerification": {
			"textVerifyingEmail": "We are checking your email, please wait.",
			"textCongratulations": "Congratulations, we validate your email",
			"textDescription": "Now you can enter the platform with your account.",
			"textError": "Error verifying",
			"textErrorDescription": "An error occurred verifying your email."
		},
		"forgotPassword": {
			"textHelpWithPwd": "Password help",
			"textAccountEmail": "Write the email address associated with your Luna Nueva Inmobiliaria account",
			"textGoToInbox": "Go to your inbox and follow the instructions to reset your password.",
			"textSuccessRequest": "The password recovery request has been sent to your email"
		},
		"resetPassword": {
			"textRestorePwd": "Restore password",
			"textNewPwd": "Write a new password",
			"textSave": "Save",
			"textError": "Error changing password",
			"textErrorDescription": "This link may have expired"
		},
		"filtersSideMenu": {
			"textApartmentsForSale": "Apartments for sale",
			"textPublishedToday": "Published today",
			"textTerrace": "Terrace",
			"textGarden": "Garden",
			"textBedroom": "bedroom",
			"textBedrooms": "bedrooms",
			"textBathroom": "bathroom",
			"textBathrooms": "bathrooms",
			"textCondition": "Condition",
			"textStudio": "Studio",
			"textNew": "New",
			"textUsed": "Used",
			"textMore": "or more",
			"textTypeOfLease": "Type of lease",
			"textTraditional": "Traditional",
			"textNoParking": "No parking"
		},
		"filtersSideNavMobile": {
			"labelAdditionalFilters": "Additional filters",
			"labelSelectFilters": "Select filters for your search",
			"labelApplyFilters": "Apply filters"
		},
		"postDetails": {
			"textBack": "Back to list",
			"textPublished": "Posted by",
			"textAgo": "ago",
			"textBy": "by",
			"linkDoTour": "Tour 360º",
			"textContactSeller": "Contact seller",
			"textLocation": "Location",
			"textDescription": "Description",
			"textOtherCharacteristics": "Other characteristics",
			"textZoneInfo": "Zone information",
			"labelBedroom": "Bd",
			"labelBedrooms": "Bds",
			"labelBathroom": "bathroom",
			"labelBathrooms": "bathrooms",
			"textGarden": "With garden",
			"textTerrace": "With terrace",
			"textYear": "year",
			"textYears": "years",
			"textMonth": "month",
			"textMonths": "months",
			"textDay": "day",
			"textDays": "days",
			"textHour": "hour",
			"textHours": "hours",
			"textMinute": "minute",
			"textMinutes": "minutes"
		},
		"propertyCharacteristics": {
			"textCharacteristics": "Characteristics",
			"totalArea": "Total area",
			"textUsefulSurface": "Useful surface",
			"textTerraceArea": "Terrace area",
			"textBedrooms": "Bedrooms",
			"textBathrooms": "Bathrooms",
			"textMaximumNumberOfInhabitants1": "Maximum number",
			"textMaximumNumberOfInhabitants2": "of inhabitants",
			"textUnitFloorNumber1": "Unir floor",
			"textUnitFloorNumber2": "number",
			"textApartmentsPerFloor1": "Apartments",
			"textApartmentsPerFloor2": "per floor",
			"textNumberOfFloors": "Number of floors",
			"textCondition": "Condition",
			"textUsed": "Used",
			"textNew": "New",
			"textOrientation": "Orientation",
			"textRealStateInformation": "Real state information",
			"textGotoRealStatePro": "Go to the real estate profile",
			"textViewMore": "See more posts from",
			"textPropertyCode": "Property code",
			"textShare": "Share"
		},
		"propertyRequirements": {
			"textRequirements1": "Requirements for",
			"textRequirements2": "this property",
			"textShowIncome": "Show your income:",
			"textDocumentsWeNeed": "Documents we need:",
			"textRent": "renting",
			"textBuy": "buying"
		},
		"characteristicsList": {
			"textNoCharacteristics": "This property doesn't have characteristics yet"
		},
		"postsNotFound": {
			"textNoProperties1": "There are no properties with the characteristics",
			"textNoProperties2": "requested, please modify your search and",
			"textNoProperties3": "try again"
		},
		"realState": {
			"posts": " posts",
			"found": "Find your favorite real estate broker: ",
			"properties": "See Properties",
			"viewAll": "See Properties all real estate brokers"
		},
		"googleMap": {
			"textExist": "There are",
			"textPropertyInArea": " properties in this area",
			"textNoProperties": "There are no properties in this area"
		},
		"favorites": {
			"path": "My searchs > Favorites",
			"textFavorites": "Favorites",
			"textNoFavorites": "You don't have favorites posts"
		},
		"notFound": {
			"textPageNotFound": "Oops! Page not found",
			"textDeletedPage": "The page you are looking for may have been removed"
		},
		"services": {
			"textServices": "Services",
			"textMore": "More",
			"tour-360": {
				"title": "Tour 360º",
				"titleIframe": "360º Tour Demo",
				"textOne": " Eliminate space or time barriers with your potential customers",
				"textTwo": " Design, production and realization of high-impact 360º virtual tours for houses, apartments, buildings and other properties",
				"subtitle": "Remove the barriers of space or time with your potential customers",
				"paragraphs": {
					"first": "Design, production and realization of high-impact 360º virtual tours for houses, apartments, buildings and other properties.",
					"second": "With our 360 virtual tours",
					"third": "With our 360 virtual tours your future buyers, tenants or the investor will tour your property at any time from any electronic device (computer / smartphone / tablet)."
				},
				"subtitleTwo": "Some benefits:",
				"list": {
					"item1": "Remove time and location limitations on potential buyers.",
					"item2": "Increase confidence about your portfolio",
					"item3": "Increase the reach of your business by allowing all users to see what you sell. ",
					"item4": "Raises the chances of selling, increasing the number of visits to the properties. ",
					"item5": "Reduces time in commercial transactions, since neither the client nor the real estate broker have to do ",
					"item6": "match your schedule to make the visit.",
					"item7": "Reduces the costs and efforts of the real estate agent, since he does not have to spend time visiting or traveling expenses.",
					"item8": "Increases the productivity of real estate agents and the company.",
					"item9": "It is compatible with the different digital channels most recommended for the commercialization of real estate."
				}
			},
			"vr": {
				"title": "Virtual reality",
				"textOne": "Present your future investment project to your inventors from anywhere, at any time and without the need for a physical presence",
				"textTwo": "With this immersive tour, your client will be able to move around the facilities at their leisure, visit each area and interact with objects in the environment without leaving your office.",
				"subtitle": "Present your future investment project to your inventors from anywhere, at any time and without the need for a physical presence",
				"paragraphs": {
					"first": "With this immersive tour, your client will be able to move around the facilities at their leisure, visit each area and interact with objects in the environment without leaving your office. The virtual tour using VR technology is a high-impact strategic tool for selling real estate projects to investors and potential clients because you can explain the project like never before."
				},
				"subtitleTwo": "Some benefits:",
				"list": {
					"item1": "Reduce time and money when starting a development to start generating sales. Present the project without depending on physical progress.",
					"item2": "Improve contact with your investors. Your investors will be able to observe carefully what changes are made to the project design, as well as follow the real estate development.",
					"item3": "Increases the chances of selling, increasing the number of visits to the properties. ",
					"item4": "Virtual tours are a clear competitive advantage as they remove the barrier of time and distance.",
					"item5": "Total visualization of the space in the real estate development. The experience of the house is increased and it is possible to give continuous feedback to fine-tune details of the project."
				},
				"titleIframe": "Virtual Reality Demonstration"
			},
			"photography": {
				"title": "Photography",
				"textOne": "Give an adequate presentation to your property and promote its rent or sale",
				"textTwo": " Photography is part of emotional marketing and is very useful to motivate your potential customers.",
				"subtitle": "Give your property a proper presentation and promote its rental or sale ",
				"paragraphs": {
					"first": "Photography is part of emotional marketing and is very useful to motivate your potential customers.",
					"second": "Real estate photography is a visual resource to excite from the first stage of the sales process. This motivates the mental tour of the property, benefits and experiences that can occur in each space."
				}
			},
			"legalServices": {
				"title": "Legal services",
				"textOne": "Our legal team specialized in real estate for foreigners and nationals will help you with the following services:",
				"textTwo": "Advice and preparation of legal opinions related to the negotiation and contracting of real estate projects, property lease agreements, real estate guarantees, among others. ",
				"subtitle": "Our legal team specialized in real estate for foreigners and nationals will help you with the following services:",
				"list": {
					"item1": "Advice and preparation of legal opinions related to the negotiation and contracting of real estate projects, property lease agreements, real estate guarantees, among others.",
					"item2": "Registry and cadastral investigation of real estate.",
					"item3": "Analysis and formalization of sales and other forms of transfer",
					"sublistItem3": {
						"item1": "Leases",
						"item2": "Usufructs",
						"item3": "Mortgages",
						"item4": "Other types of liens"
					},
					"item4": "Planning of the legal/fiscal sale strategy of the real estate project.",
					"item5": "Constitution of a horizontal property regime, in condominium or any other that is required, and preparation of its respective regulations"
				}
			},
			"saleRent": {
				"title": "We sell or rent your property",
				"textOne": "We sell your property safely. We look for the right client suiting your needs and select those intending to purchase",
				"textTwo": "Our sales team will be in charge of finding the ideal client for renting or selling your property.",
				"subtitle": "We sell your property safely. We look for the right client suiting your needs and select those intending to purchase",
				"paragraphs": {
					"first": "Our sales team will be in charge of finding the ideal client for renting or selling your property..  ",
					"second": "How much do we charge?   "
				},
				"textSale": "Sale",
				"textValueOfSale": "of the sale value ",
				"textRent": "Rent",
				"textValueFirstRent": "of the value of the first rent."
			},
			"management": {
				"title": "Property Management",
				"textOne": "Do you own one or multiple properties in Antigua Guatemala and find yourself short on time to take care of them?",
				"textTwo": "Don't worry, we're here to help. Our property management and administration service is designed for busy property owners like yourself...",
				"subtitle": "Do you own one or multiple properties in Antigua Guatemala and find yourself short on time to take care of them?",
				"paragraphs": {
					"first": "Don't worry, we're here to help. Our property management and administration service is designed for busy property owners like yourself who want to maximize their income without dealing with the tedious task of managing their property. From communicating with guests to efficiently handling hosting platforms like Airbnb, we ensure that your property is always visible and attracts the right travelers.",
					"second": "Our team will handle inquiries, manage bookings, and provide exceptional guest support to guarantee an unforgettable hosting experience. Additionally, we take care of all aspects related to the cleaning and maintenance of your property. We'll make sure every corner is spotless and ready to welcome guests.",
					"third": "Even if your property is not listed on hosting platforms and is available for short, medium, or long-term rentals, our service remains the perfect solution. We take care of everything, from finding clients for property rentals and their management to handling contracts and payments.",
					"fourth": "Free yourself from stress and let our expert property management team take care of it all. We ensure that your property becomes a hassle-free source of income while you enjoy your leisure time."
				}
			},
			"interiorDesign": {
				"title": "Interior Design",
				"textOne": "Have you completed about 80% of furnishing your living space but still feel like there's an elusive charm missing, that indescribable 'je-ne-sais-quoi'?",
				"textTwo": "Don't worry, we're here to provide the finishing touches that will truly make your home come to life. Our styling services go beyond the ordinary, delving into the meticulous sourcing of those final elements.",
				"subtitle": "Have you completed about 80% of furnishing your living space but still feel like there's an elusive charm missing, that indescribable 'je-ne-sais-quoi",
				"paragraphs": {
					"first": "Don't worry, we're here to provide the finishing touches that will truly make your home come to life. Our styling services go beyond the ordinary, delving into the meticulous sourcing of those final, distinctive elements from hand-picked decorative items to carefully selected design pieces and captivating artworks that add character and personality to your living space.",
					"second": "Our pricing structure is simple: our services are available at a rate of $100 per hour (excluding taxes), with a minimum engagement of 4 hours for every project. We tailor our approach based on your unique needs, considering the size of your home or the specific areas requiring a stylistic transformation, including total square footage, the number of rooms, and even the walls that need that special touch. Furthermore, we take into account your available sourcing budget to provide you with a comprehensive quote for our design services and an estimated budget for sourcing, if you haven't already defined one.",
					"third": "All we ask from you is to share a floor plan, as well as detailed photos and videos of the areas yearning for our creative infusion. Whether your living space is grand and expansive or intimate and cozy, our mission is to turn it into a reflection of your unique personality and style. With our expert touch, we will meticulously curate each element to ensure that your home exudes warmth, character, and authenticity.",
					"fourth": "Let us be your creative partners in the journey of elevating your living space to its full potential. Don't hesitate to reach out and let us transform your home into a true masterpiece!",
					"fifth": "Powered by STUDIO MACHTELD OOSTERBAAN"
				}
			},
		},
		"serviceDetail": {
			"backToList": "Back to list",
			"requestService": "Request service"
		},
		"us": {
			"usTitle": "Who we are",
			"usTexT": "At Luna Nueva, we are more than just a real estate company; we are a community dedicated to social impact and cultural enrichment at the heart of Antigua Guatemala. We are passionate about property sales and rentals, short-term property management, and personalized support for buying and renting homes. With a strong commitment to empowering our people and supporting local initiatives, we believe every transaction and project has the potential to create positive change.",
			"usSubTitle": "Our Social Commitment",
			"usSubtitleTexts": "At Luna Nueva, each service is driven by a mission: to contribute to the development of our community. This commitment comes to life through our property management program, designed to generate value and well-being in Antigua Guatemala:",
			"education": "Free Education for Our Community",
			"educationTexts": "We believe knowledge transforms lives. Through our online courses, we offer continuous growth opportunities for our team members and their families. From English classes to new skill development, we open doors for everyone in our community to reach their potential and achieve their dreams.",
			"training": "Training to Offer Traditional Guatemalan Breakfasts",
			"trainingTexts": "We celebrate Guatemalan culture from the very first bite of the day. We have trained our team so that some of our guests can enjoy authentic Guatemalan breakfasts, creating an experience that goes beyond tourism and connects visitors to the country’s traditions. With each meal, we honor Guatemala's heritage and provide our team with new learning and growth opportunities.",
			"art": "Supporting Local Art: Spaces for Artists",
			"artTexts": "We believe in the power of art to tell stories and elevate culture. That’s why we have created spaces within our properties where Guatemalan artists can showcase their work. We want our guests to experience the essence of our country and appreciate the talent of local creators, whose art reflects Guatemala's identity and beauty.",
			"lunaNuevaPurpose": "This is our purpose at Luna Nueva:",
			"purposeSubtitle": "Generate memorable experiences and build a positive legacy in every corner of Antigua Guatemala.",
			"welcomeText": "Welcome to be part of a community that dreams, grows and builds together"
		},
		"blog": {
			"home": {
				"TITLE": "Welcome to the Luna Nueva <br> Real Estate Portal Blog",
				"SUBTITLE": "Find the information you need to stay up to date and to make your best decision",
				"LINKDETAIL": "More information.",
				"CARD1": {
					"TITLE": "Benefits of living in Antigua Guatemala: safety counts",
					"TEXT1": "Come and live in Guatemala (or, more specifically, Antigua Guatemala); you will absolutely adore it!",
					"TEXT2": "All our team lives here, and we love it. We promise you will make it feel like home, and you will tell everyone about the fantastic time you are having in the Land of Eternal Spring.",
					"DATEPOST": "Posted on April 10, 2023"
				},
				"CARD2": {
					"TITLE": "Understanding the particularities of Antigua Guatemala: what makes us unique",
					"TEXT1": "For us, the Mexican era has ended, and we must now discover what lies beyond. As the saying goes, \"Been there, done that!\" sums it up pretty well. ",
					"TEXT2": "With the digital nomad era, a lot of opportunities have opened up, and the world is yours!",
					"DATEPOST": "Posted on April 10, 2023"
				},
				"CARD3": {
					"TITLE": "Antigua or Guatemala City?",
					"TEXT1": "So you are wondering what the best next step is when you choose Guatemala for your ex-pat life?",
					"TEXT2": "While it all depends on your needs and preferences, we have some insights that might help you discover what is right for you and your family.",
					"DATEPOST": "Posted on April 10, 2023"
				},
				"CARD4": {
					"TITLE": "Walking the Streets of Antigua Guatemala: A Journey into Historical Beauty",
					"TEXT1": "Antigua Guatemala, with its colonial charm and rich history, is a destination that captivates travelers with its beauty and unique atmosphere. Its cobblestone streets and colorful facades transport those who wander through them to a fascinating past.",
					"TEXT2": "Its cobblestone streets and colorful facades transport those who wander through them to a fascinating past.",
					"DATEPOST": "Posted on July 26, 2023"
				},
				"CARD5": {
					"TITLE": "Chicken Buses: The Colorful Journey through Antigua Guatemala's Urban Landscape",
					"TEXT1": "As a passionate traveler, I thrive on discovering unique experiences that immerse me in the local culture.",
					"TEXT2": "When I first heard about the legendary \"chicken buses\" of Antigua Guatemala, I knew I had to embark on this vibrant adventure.",
					"DATEPOST": "Posted on July 26, 2023"
				},
				"CARD6": {
					"TITLE": "5 Tips to Master the Art of House Hunting in 2024",
					"TEXT1": "Welcome to the future of house hunting! In the ever-evolving landscape of real estate, finding your dream home or making a successful sale is an art form.",
					"TEXT2": "With 2024 upon us, let's explore five dynamic tips that will transform your house hunting experience into an exciting, efficient, and rewarding journey.",
					"DATEPOST": "Publish in january 09, 2024"
				},
				"CARD7": {
					"TITLE": "Exploring the Magic of Holy Week in Antigua Guatemala",
					"TEXT1": "In the picturesque cobblestone streets of Antigua Guatemala, Holy Week comes alive in a way that captivates the senses and nourishes the soul.",
					"TEXT2": "This ancient colonial city transforms into a stage of devotion, tradition, and color during the celebration of Holy Week, becoming a must-visit destination for travelers seeking to experience the magic and mystique of this unique festival.",
					"DATEPOST": "Publish in february 23, 2024"
				},
				"CARD8": {
					"TITLE": "Magical Afternoons: Enjoying the Rain in Antigua Guatemala",
					"TEXT1": "Every time it rains in the afternoon, a spectacle of renewal and magic takes place.",
					"TEXT2": "These are moments when the sky turns gray and the raindrops dance on the colonial cobblestones, creating a scene that seems to have come out of a Gabriel García Márquez story.",
					"DATEPOST": "Published on April 11, 2024"
				},
				"CARD9": {
					"TITLE": "Luna Nueva arrives in Miami: A new stage",
					"TEXT1": "Every time it rains in the afternoon, a spectacle of renewal and magic takes place.",
					"TEXT2": "These are moments when the sky turns gray and the raindrops dance on the colonial cobblestones, creating a scene that seems to have come out of a Gabriel García Márquez story.",
					"DATEPOST": "Published on April 11, 2024"
				},
				"CARD10": {
					"TITLE": "Luna Nueva arrives in Miami: A new stage",
					"TEXT1": "At Luna Nueva, we have always believed that every project, property, and service has the potential to connect people, cultures, and dreams.",
					"TEXT2": "Since our beginning in Antigua Guatemala, we have been dedicated to creating something more than a real estate and hospitality company; we have built a community that shares our vision of social impact, personalized service, and love for what we do.",
					"DATEPOST": "Posted on January 13, 2025"
				}
			},
			"detail": {
				"ARTICLE1": {
					"DATEPOST": "Posted on April 10, 2023",
					"SECTION1": {
						"TITLE": "Benefits of living in Antigua Guatemala: safety counts",
						"TEXT1": "Come and live in Guatemala (or, more specifically, Antigua Guatemala); you will absolutely adore it! All our team lives here, and we love it. We promise you will make it feel like home, and you will tell everyone about the fantastic time you are having in the Land of Eternal Spring.",
						"TEXT2": "Isn't it funny how we tend to put ourselves off doing something amazing simply because it's new? According to studies, standing close to the edge of your comfort zone for a while will cause you to push that line and expand your comfort zone. Antigua Guatemala is the ideal location to start your life abroad, and here is why: Antigua Guatemala is a small city that is perfectly established to have all the commodities you need and is designed for the hospitality industry.",
						"TEXT3": "While Antigua Guatemala is Guatemala's most popular tourist destination, it is also home to many foreigners who have chosen to live here, and you can be the next one! Some of the new locals have already traveled the world and decided that Antigua Guatemala was their best bet. We will explain why in later posts, so please click the subscription button to be notified when we do. But first things first. Let's take a look at some of the most common misconceptions about why people believe it is not a safe place to live"
					},
					"SECTION2": {
						"TITLE": "Antigua Guatemala Volcano Eruption",
						"TEXT1": "It goes without saying that Antigua Guatemala is surrounded by three volcanoes, one of which is extremely active. Active, but under control. All of the antigueños prefer it to be active because it not only releases energy that keeps the situation going, but it also puts on a show every night that is very entertaining to watch even if they have lived here for a long time. We are sure you will grow a passion for watching it at night with a glass of wine in your hand",
						"TEXT2": "Imagine hiking Acatenago to go see Volcan the Fuego at night and spend the night camping, or use a 4-wheeler to get there. Is okay if you are not into that; you can always hire a private pilot for a helicopter ride.  We bet each of them is quite an experience in itself!  On this note, we must add that common sense is always recommended. Please book a tour with an experienced guide if you want to hike the volcano in Antigua. You can easily become disoriented or injure yourself; remember that you are out in nature, and the great outdoors is something you must respect, especially when the conditions change so abruptly."
					},
					"SECTION3": {
						"TITLE": "Antigua Guatemala weather year-round",
						"TEXT1": "The best time to visit Guatemala is during your favorite season, which varies from person to person, but why not stay and live here and experience them all? Don’t let other people tell you that visiting Antigua from November to March (or during the dry season) is the best and only option. If you come during the rainy season, you'll be surprised at how beautiful and flourishing everything is. Each season has its advantages and disadvantages, but it is entirely up to you and how you want to live your experience.",
						"TEXT2": "Contrary to the northern or southern hemispheres, Central America has seasons divided into two major groups: the dry season and the rainy season.  The dry season includes major holidays such as Christmas, New Year's, Lent, and Easter, whereas the wet season includes Mother's Day, Father's Day, and Independence Day. Each one of them has its own charm.",
						"TEXT3": "To return to the original question, is it safe to be in Antigua Guatemala during the rainy season? The answer is yes. Even so, you should avoid going outside while it is raining hard, as the streets can rapidly get flooded, especially if it’s been raining hard for a while. Unfortunately, sewer blockages and slidings are still a thing, but the authorities work to fix them very quickly so they don’t cause major traffic delays."
					},
					"SECTION4": {
						"TITLE": "Can you eat out in Antigua?",
						"TEXT1": "Do you have a sensitive stomach? No worries! There are many options to choose from, so it is safe to eat out in Antigua Guatemala. You will learn to adapt and follow some basic rules, such as not drinking tap water, washing your hands before eating, avoiding eating at street stands and restaurants with little traffic, avoiding cabbage and lettuce, and being aware of your own food allergies and sensitivities. There should be no major concerns about eating locally while living in Guatemala.",
						"TEXT2": "Some of this advice is common sense, but let us explain why you should keep an eye on the leafy vegetables you eat during your visit and avoid low-traffic restaurants. The simple reason is that the ground plantations are more exposed to E. coli, and rinsing the vegetables is insufficient to kill bacteria. On the other hand, low-traffic restaurants mean less rotation of inventory and more chances of food being stale or spoiled. Furthermore, avoid taking food to go because you will most likely keep it out of a fridge for a while, and it can easily spoil the food."
					},
					"SECTION5": {
						"TITLE": "Walking around Antigua Guatemala",
						"TEXT1": "Being worried about your personal safety is very important, and it's part of our primitive instincts. But let’s be real: there is crime everywhere, even in the more developed countries. Antigua Guatemala is not the exception, but on the bright side, violence is under control, and petty crime is the only sensible thing. Nothing that being self-aware can’t help.",
						"TEXT2": "So how do you optimize your security level while living here? That's simple: avoid dark and lonely streets at night, only assist if your security isn’t jeopardized, don't go underground after parties (which are 100% illegal due to the cut-off time), don't try to buy illegal substances, avoid going into chicken buses that go outside Sacatequez (for example, the bus that goes to Guatemala City), stay focused while doing credit or debit card transactions, so they don't clone the card or do a double transaction, and most importantly, prepare a contingency plan in case things do happen, so you know what to do next. "
					},
					"SECTION6": {
						"TITLE": "Takeaways",
						"TEXT1": "Reconsider where the concern comes from and investigate how to find a workaround to enjoy your new life in Antigua Guatemala. Subscribe to get more tips about your trip to Antigua and share them with your friends so they can also discover the wonders of the heart of the Mayan world."
					}
				},
				"ARTICLE2": {
					"DATEPOST": "Posted on April 10, 2023",
					"SECTION1": {
						"TITLE": "Understanding the particularities of Antigua Guatemala: what makes us unique",
						"TEXT1": "For us, the Mexican era has ended, and we must now discover what lies beyond. As the saying goes, \"Been there, done that!\" sums it up pretty well.  With the digital nomad era, a lot of opportunities have opened up, and the world is yours! So, now that we're on the same page, why not give Guatemala a chance? Have you heard of a cute little town called Antigua Guatemala? We have everything you need to live a comfortable life similar to the one you have at home. Some of us think it contains all the commodities of a big city with the luxury of living a low-key life that only small towns can provide.",
						"TEXT2": "Tell us in the comment section about your likes and interests. Most likely, we have it here in Antigua Guatemala as well. We are proud to live in a multicultural city that accommodates a wide variety of lifestyles, from the ultra-minimalist to the ultra-opulent, from the career-oriented to the family-oriented, not even talking about the spiritual opportunities a place with so many cultures offers. So let’s first dive into the particularities of this gem."
					},
					"SECTION2": {
						"TITLE": "Getting to know the local culture of Antigueños",
						"TEXT1": "This is such a wide topic, so let’s divide it into two big categories that will help us exemplify what is palpable. The first category would be the Guatemalan influence, and the second one would be foreign influence. And even though we will study them separately to understand them better, we must clarify that they both coexist and influence each other, which is the beauty of this town and makes it so authentic and different at the same time.",
						"TEXT2": "So let's start with the Guatemalan side, which is really fascinating to get to know how so many different generations and ethnicities contribute to the national identity. Elders still hold up to the traditional socializing methods that help foster a hospitable and welcoming culture. Younger generations want to learn more about the world and have the energy to try out new ways of living, such as holistic and artistic lifestyles. They also want to make their work more international. But what does that mean to you as a potential ex-pat? It means you will find peers in Guatemala and potentially partner with them to do amazing things online.",
						"TEXT3": "Now let's talk about foreign influence. Decade after decade, foreigners have come and settled in Guatemala, giving us amazing gifts such as Queso Chancol, which is now well-recognized and enjoyed by many Guatemalans. And if we were to review contributions on a smaller scale and more local to Antigua Guatemala, we could see improvement in many industries, including the education sector, which has opened its doors to have international grade programs, making it so much easier for a family that is looking to transition to an ex-pat life and for locals to pursue a career abroad. Another important change is the growth of the hospitality industry, which puts Guatemala on the map (because of its excellent bilingual service) and helps keep the economy going. And what about food? Is there any influence on gastronomy? Let’s dive into that in the next section."
					},
					"SECTION3": {
						"TITLE": "The fork journey in Antigua Guatemala",
						"TEXT1": "Antigua Guatemala used to be a town that was cut off from the urban life of the surrounding area and city life. As a result, it developed its own distinct culinary traditions. There were a lot of candy businesses in this area, and their wide selection of treats was enough to entice anyone craving sweets. Even though the only food options were candy and a few traditional dishes, the arrival of tourists and investors from other countries greatly increased the options.",
						"TEXT2": "Antigua Guatemala has undergone a transformation to cater to a wider variety of customers, including those who are interested in trying traditional Guatemalan cuisine as well as those who are curious about other types of cuisine from across the world. We bet your overall experience in this land will be enhanced as a result, and you will feel at home in a shorter time.",
						"TEXT3": "Some of the local restaurants go out of their way to offer great service by creating one-of-a-kind settings that offer memorable experiences and environments that don't discriminate between different age groups, making it a great place for the whole family to go. For this reason, we believe that restaurants really outdo themselves throughout the course of time and continue to introduce a novelty component that will keep you hooked and keep you going back again and again. Because of this, the diet is more diversified, and so are the surroundings, depending on where you can take your friends and family."
					},
					"SECTION4": {
						"TITLE": "Site seeing & vibes",
						"TEXT1": "Yes, Antigua has a lot of history, but let’s be honest—that's not the highlight for a lot of people that choose to come and live here unless you are an enthusiast in history, archeology, or sociology. But you know what Antigua’s highlight is? Site seeing! What could be more fun than going out and exploring what this picturesque town has to offer? Believe it or not, you can spend a whole year exploring places and still find new spots to go to. Or even get excited about new nurseries that open up and carry a new plant you haven’t seen anywhere else or that new cute little store that opens up and brings a lot of nostalgic imports that make you feel blessed to be here and have a piece of your motherland at the same time.",
						"TEXT2": "And just to add more, every time you visit a new place, you will find a particular smell or sound that will become very representative of it, and you will remember it every time you go. Just like the local bans, that is something to fall in love with. They are all so talented and unique that you can easily plan your week around their presentation schedules at restaurants, but that is something we will talk about in another post, along with other reasons to choose Antigua Guatemala over any other part of the world. Hit the subscribe button to get notifications, and feel free to share this information with your friends."
					}
				},
				"ARTICLE3": {
					"DATEPOST": "Posted on April 10, 2023",
					"SECTION1": {
						"TITLE": "Antigua or Guatemala City?",
						"TEXT1": "So you are wondering what the best next step is when you choose Guatemala for your ex-pat life? While it all depends on your needs and preferences, we have some insights that might help you discover what is right for you and your family. Go get your snacks and tea because we are about to dive into the juicy part where we tell you what no other site or person will tell you in depth. We will talk about everything from mobility to where to find those good moments you like."
					},
					"SECTION2": {
						"TITLE": "Getting around",
						"TEXT1": "Town life and city life have a major difference in lifestyles, starting from the way you transport yourself to other places. While big cities like Guatemala City use cars and motorcycles as a common way of transportation, Antigua Guatemala allows other fun experiences like taking a tuc-tuc ride or just walking around. With this, we are not saying that Antigua doesn’t have the means to handle vehicles. Both places have facilities for driving around and parking as well. Also, they both have collective transportation like chicken buses, but it is not recommended to take them to the city as they tend to be very hectic and have bad reputations. On the other hand, buses in Antigua Guatemala, are very safe to use and easy to catch from the terminal or on your way to the place you were walking to.",
						"TEXT2": "Once you get to know a few tuc-tuc drivers that you trust, you can ask them for their phone numbers, and they will act as your taxi on demand. Their fees might vary depending on the number of people in your party or the distance you drive, but most likely, they will all range from Q15 to Q30. Bus drives go anywhere from Q3 to Q7 within Sacatepequez, but if you want to go beyond that, it could cost around Q15-Q20 per bus."
					},
					"SECTION3": {
						"TITLE": "Distance from place to place",
						"TEXT1": "Guatemala City is very well known for its traffic during peak hours, which is now getting out of hand. Lots of people are choosing to leave at 4 a.m. in the morning to park outside their jobs or universities and take a nap on the spot just to save time and gas on their commute, but that is not the same story for Antigua Guatemala. One of the wonders of this charming town is that it really displays what a 15-minute city should look like. No matter where you are, you can walk a few blocks and find a pharmacy (or drugstore, however you call it), banks, and places to eat or make small purchases for your daily use. People in this area tend to do remote work, so they don’t need to commute, and streets have fluid traffic except on holidays like Easter or the Flower Festival.  "
					},
					"SECTION4": {
						"TITLE": "Comparison of lifestyles",
						"TEXT1": "City life is for those who want to stay connected with corporate work and have the luxury of enjoying fancy restaurants and branded stores with prestigious schools. But we don’t have to give up on all of that just because. We can have a little bit of that with many leisure activities that help us decompress without taxing our bodies. That is Antigua Guatemala, a little oasis in the desert. This land has much to offer, from family-friendly activities to extreme hikes that never get old.",
						"TEXT2": "We know it is hard to think that a place could have it all, but Antigua is there to break that stigma and show you that there is another way. You can still enjoy going to upscale restaurants while being much closer to your house, which means there is no need to have a designated driver and all your guests can have a drink. Or it also means you can have dinner in one place and dessert in another, only a few steps away.",
						"TEXT3": "And in addition to that, if you are interested in the quality upbringing of your children, we believe this is the place for you. You will be able to teach your children to have a slow pace life that is connected to nature and agriculture. Some local schools have alternative teaching methods and would teach your kids about the valuable things that Mother Earth has to offer. Just to mention a few examples, there is one school that is attached to a coffee plantation and has built its own butterfly farm. Other smaller schools prefer to keep their classes small and give more attention to every kid, limiting their groups to 10 kids per teacher."
					},
					"SECTION5": {
						"TITLE": "Ecotourism",
						"TEXT1": "City life can be stressful, but you can always opt for an alternative and healthier lifestyle. On this note, the capital doesn’t have as many options as  Antigua Guatemala. Here, we have a lot of natural trails that you can visit. Some of them are free as they are out of the way, and others are paid but very much worth it. Who would imagine that you could have a rainforest in the middle of a picturesque town? Yes! This is the land of opportunity, and it doesn’t stop there. You don’t have to be a high-impact athlete to enjoy the views. You can easily opt for easy trails such as the iconic Cerro de la Cruz, especially now that it has been remodeled and designed to enjoy concerts out there."
					},
					"SECTION6": {
						"TITLE": "Pet friendliness",
						"TEXT1": "And last but not least, let’s talk about our furry friends’ well-being. It is not a joke that dogs are today’s babies, and we have become more aware of their need for socialization. Both the city and Antigua offer pet-friendly shopping places, but we believe that Antigua has come much closer to becoming a dog’s heaven. It is no longer customary to ask if dogs are welcome somewhere because they are all welcome as long as there is no sign that indicates otherwise. These signs are usually found only in supermarkets, hospitals, monuments, and banks, but that is understandable. Unfortunately, not all pet owners are as conscientious about their pets' grooming and training, so they shouldn't be allowed in public spaces where they could pose a threat to people's health or disrupt an otherwise formal atmosphere. But apart from these 4 types of places, your 4 legged friend will be welcome everywhere, and you will enjoy living differently, especially when you take advantage of the trails that only Antigua has to offer."
					}
				},
				"ARTICLE4": {
					"DATEPOST": "Posted on July 26, 2023",
					"SECTION1": {
						"TITLE": "Walking the Streets of Antigua Guatemala: A Journey into Historical Beauty",
						"TEXT1": "Antigua Guatemala, with its colonial charm and rich history, is a destination that captivates travelers with its beauty and unique atmosphere. Its cobblestone streets and colorful facades transport those who wander through them to a fascinating past."
					},
					"SECTION2": {
						"TEXT1": "Venturing into the narrow streets of Antigua, a vibrant and energizing ambiance envelops visitors. The colonial buildings, with their wrought-iron balconies and vivid facades, provide the perfect backdrop for the curious traveler. Every corner reveals a new story, a connection to the past that remains alive in each stone."
					},
					"SECTION3": {
						"TEXT1": "One of the most captivating aspects of walking the streets of Antigua Guatemala is the presence of historical churches and convents. These beautiful religious structures, such as the Cathedral of Santiago and the Church of La Merced, stand as silent witnesses to centuries of devotion and faith. Their baroque interiors, adorned with intricately carved altarpieces and fragments of sacred art, are a visual delight for any lover of history and architecture."
					},
					"SECTION4": {
						"TEXT1": "However, Antigua is not only a city of churches and convents. Its streets also house charming plazas, where visitors can sit under the shade of trees and soak in the relaxed atmosphere. The Main Plaza, surrounded by majestic colonial buildings and the imposing façade of the Palace of the Captains General, is the heart of the city and an ideal spot to observe the daily bustle."
					},
					"SECTION5": {
						"TEXT1": "As one immerses oneself in Antigua's cobblestone streets, a plethora of craft shops, cozy cafés, and restaurants offering gastronomic delights come into view. From traditional textiles to handmade jewelry, local art and craftsmanship are an integral part of the walking experience in the city."
					},
					"SECTION6": {
						"TEXT1": "Yet, beyond its tangible beauty, walking the streets of Antigua Guatemala also involves immersing oneself in its cultural spirit. Colorful festivals, live music, and the hospitality of its people create a warm and festive ambiance. Locals take pride in sharing their cultural heritage and are eager to recount stories that resonate with visitors' souls.",
						"TEXT2": "In summary, the experience of walking the streets of Antigua Guatemala is a journey through time, combining history, architecture, art, and culture. It is a place where the past intertwines with the present, and every step is an opportunity to uncover a new story waiting to be told."
					}
				},
				"ARTICLE5": {
					"DATEPOST": "Posted on July 26, 2023",
					"SECTION1": {
						"TITLE": "Chicken Buses: The Colorful Journey through Antigua Guatemala's Urban Landscape",
						"TEXT1": "As a passionate traveler, I thrive on discovering unique experiences that immerse me in the local culture. When I first heard about the legendary \"chicken buses\" of Antigua Guatemala, I knew I had to embark on this vibrant adventure. Little did I know that stepping onto one of these vividly decorated vehicles would not only be a mode of transportation but also a thrilling journey through the heart and soul of the city."
					},
					"SECTION2": {
						"TEXT1": "As I approached the bustling bus station, a symphony of honking horns and animated conversations filled the air. The sight before me was a feast for the eyes—a fleet of old American school buses, transformed into dazzling works of art. Each bus boasted its own kaleidoscope of colors, ornate patterns, and whimsical designs, proudly displaying the creativity and ingenuity of the local artisans."
					},
					"SECTION3": {
						"TEXT1": "With a sense of anticipation, I climbed aboard one of these rolling masterpieces. The interior welcomed me with a lively atmosphere—upbeat music playing in the background, laughter echoing through the air, and a warm camaraderie among passengers from all walks of life. The bus itself seemed to come alive, pulsating with energy and character that perfectly mirrored the vibrant spirit of Antigua."
					},
					"SECTION4": {
						"TEXT1": "As we navigated the city streets, I marveled at the bus driver's skillful maneuvering, gracefully squeezing through narrow spaces and seamlessly merging with the colorful tapestry of traffic. The ride itself was an adrenaline-fueled adventure, as we darted through the maze of cobblestone streets, each twist and turn offering a new surprise. It was as if the bus had a personality of its own, leading us through a captivating urban landscape."
					},
					"SECTION5": {
						"TEXT1": "But beyond the exhilarating ride, what truly struck me was the sense of community within the bus. People of all ages, backgrounds, and stories intermingled, their conversations blending into a harmonious symphony of shared experiences. Strangers became friends, laughter became a common language, and the bus transformed into a mobile melting pot of cultures and connections.",
						"TEXT2": "As we stopped at various locations to pick up and drop off passengers, the bus became a microcosm of Antigua's daily life. I observed street vendors hopping on, their colorful wares stacked high. Local families with children giggling and waving at friends along the way. Even the occasional clucking of chickens, true to the bus's nickname, added a touch of rural authenticity amidst the urban chaos.",
						"TEXT3": "Eventually, my journey on the chicken bus came to an end. Stepping off the bus, I carried with me a profound sense of awe and appreciation for this unique mode of transportation. Beyond its practical function, the chicken bus symbolizes the indomitable spirit of the Guatemalan people—a testament to their resourcefulness, creativity, and unwavering sense of community.",
						"TEXT4": "So, if you find yourself in Antigua Guatemala, don't hesitate to hop aboard one of these colorful wonders. Embrace the vibrant chaos, strike up conversations with fellow passengers, and let the journey take you on an unforgettable ride. The chicken bus is not just a means of getting from point A to point B—it's a captivating storytelling experience that will leave you with memories that will last a lifetime."
					}
				},
				"ARTICLE6": {
					"DATEPOST": "Published on January 09, 2024",
					"SECTION1": {
						"TITLE": "5 Tips to Master the Art of House Hunting in 2024",
						"TEXT1": "Welcome to the future of house hunting! In the ever-evolving landscape of real estate, finding your dream home or making a successful sale is an art form. With 2024 upon us, let's explore five dynamic tips that will transform your house hunting experience into an exciting, efficient, and rewarding journey."
					},
					"SECTION2": {
						"TITLE": "Tip 1: Embrace Virtual Reality Tours",
						"TEXT1": "In the age of technological advancement, bid farewell to traditional open houses and welcome virtual reality tours. Dive into a property from the comfort of your couch, exploring every nook and cranny without physically being there. Discover how this innovative approach makes house hunting a breeze."
					},
					"SECTION3": {
						"TITLE": "Tip 2: Leverage Personalized AI Assistance",
						"TEXT1": "Say hello to your virtual real estate assistant! AI-powered algorithms personalize your property search, presenting options tailored to your preferences. From specific neighborhoods to desired amenities, witness how AI streamlines your quest for the perfect home."
					},
					"SECTION4": {
						"TITLE": "Tip 3: Eco-Friendly Features are the New Black",
						"TEXT1": "The eco-conscious movement is shaping the real estate landscape. Discover how properties equipped with sustainable features not only contribute to a greener planet but also enhance your lifestyle and home value. Let's explore how solar panels, energy-efficient appliances, and eco-friendly designs elevate your house-hunting checklist."
					},
					"SECTION5": {
						"TITLE": "Tip 4: Emphasize Community and Lifestyle",
						"TEXT1": "It's not just about the house; it's about the lifestyle it offers. Dive into the importance of choosing a property that resonates with your desired community vibe. Explore how amenities, neighborhood culture, and local activities play a pivotal role in your house-hunting decisions."
					},
					"SECTION6": {
						"TITLE": "Tip 5: Negotiation Strategies for Successful Deals",
						"TEXT1": "Unveil the art of negotiation! Understand the market trends, pricing strategies, and tactics to seal the deal. Learn how to navigate bidding wars, leverage your position, and secure the best possible outcome whether buying or selling.",
						"TEXT2": "Conclusion:",
						"TEXT3": "2024 brings a myriad of opportunities and innovations in the real estate realm. These five tips are your arsenal for conquering the house-hunting landscape in the new year. Embrace the evolution, redefine your approach, and embark on a house-hunting journey that's both enjoyable and successful.",
						"TEXT4": "Remember, Luna Nueva Marketplace is your gateway to a seamless real estate experience in 2024 and beyond!"
					}
				},
				"ARTICLE7": {
					"DATEPOST": "Posted on february 23, 2024",
					"SECTION1": {
						"TITLE": "Exploring the Magic of Holy Week in Antigua Guatemala",
						"TEXT1": "In the picturesque cobblestone streets of Antigua Guatemala, Holy Week comes alive in a way that captivates the senses and nourishes the soul. This ancient colonial city transforms into a stage of devotion, tradition, and color during the celebration of Holy Week, becoming a must-visit destination for travelers seeking to experience the magic and mystique of this unique festival."
					},
					"SECTION2": {
						"TEXT1": "The processions, which are the heart of Holy Week in Antigua Guatemala, are a breathtaking spectacle that leaves an indelible mark on the memory of those fortunate enough to witness them. From Palm Sunday to Easter Sunday, the streets are filled with the sound of drums, the aroma of flowers, and the fervent devotion of Guatemalans participating in these expressions of faith."
					},
					"SECTION3": {
						"TEXT1": "Each procession is accompanied by impressive floats carrying richly decorated religious images, as the faithful carry them on their shoulders through streets adorned with vibrant carpets. These carpets, meticulously crafted with dedication, are an expression of the community's deep faith and creativity and represent an integral part of the Holy Week experience in Antigua Guatemala."
					},
					"SECTION4": {
						"TEXT1": "But beyond the visual beauty of the processions and carpets, Holy Week in Antigua Guatemala is a celebration that delves into the rich cultural and religious tradition of the country. It is an opportunity to connect with Guatemala's history and spirituality, and to experience firsthand the devotion and fervor of its people."
					},
					"SECTION5": {
						"TEXT1": "During this sacred week, visitors have the chance to immerse themselves in the unique atmosphere of Antigua Guatemala, exploring its charming colonial churches, savoring delicious local cuisine, and participating in the numerous cultural activities organized in honor of Holy Week."
					},
					"SECTION6": {
						"TEXT1": "In summary, Holy Week in Antigua Guatemala is much more than a religious celebration; it is a transformative experience that blends spirituality, tradition, and culture in an atmosphere of beauty and solemnity. It is a reminder of the importance of faith and community, and an invitation to all travelers to immerse themselves in the magic and mystique of this unforgettable festival."
					}
				},
				"ARTICLE8": {
					"DATEPOST": "Posted on April 11, 2024",
					"SECTION1": {
						"TITLE": "Magical Afternoons: Enjoying the Rain in Antigua Guatemala",
						"TEXT1": "Every time it rains in the afternoon, a spectacle of renewal and magic takes place. These are moments when the sky turns gray and the raindrops dance on the colonial cobblestones, creating a scene that seems to have come out of a Gabriel García Márquez story."
					},
					"SECTION2": {
						"TEXT1": "Last year, the rains were unusual and challenged the usual weather patterns, surprising everyone. Despite this, instead of being a cause for concern, this climatic variability reminded us of the importance of valuing every moment, even when nature chooses to follow its own course."
					},
					"SECTION3": {
						"TEXT1": "In Antigua Guatemala, rain is more than a weather phenomenon; the mountains and vegetation are just as important as the ruins and cobbled streets in its essence. It is a gift for the thirsty land, a blessing for plants and trees to flourish and grow in all their splendor with these waters. The vegetation is renewed, the colors acquire more intensity and the air is filled with freshness, forming a landscape that bewitches the senses."
					},
					"SECTION4": {
						"TEXT1": "The rain in Antigua Guatemala has a special charm when observed from the comfort of a window, although beyond its importance for the local ecosystem. The sound of the drops hitting the roofs, the smell of wet earth in the air and the sight of the leaves of the trees swaying gently in the wind invite reflection and contemplation."
					},
					"SECTION5": {
						"TEXT1": "Those who live in or visit this charming city are fortunate that rainy afternoons give them the opportunity to disconnect from the daily hustle and bustle and immerse themselves in the tranquility that only nature can offer."
					}
				},

				"ARTICLE9": {
					"DATEPOST": "Publicado el 26 septiembre, 2024",
					"SECTION1": {
						"TITLE": "Un Property Management con Impacto Social: El Compromiso de Luna Nueva",
						"TEXT1": "Hola, soy Jharan Marroquín, CEO de Luna Nueva, y hoy quiero compartir contigo algo que nos diferencia en el mundo de la administración de propiedades en Antigua Guatemala. No somos solo una empresa que gestiona la renta a corto plazo de propiedades, somos un proyecto con propósito. En Luna Nueva, hemos creado un modelo que no solo busca rentabilidad, sino también generar un impacto social tangible en nuestra comunidad."
					},
					"SECTION2": {
						"TITLE": "¿Qué es Property Management en Luna Nueva?",
						"TEXT1": "Cuando hablamos de property management, estamos hablando de un servicio integral, de un círculo de 360 grados. Desde la limpieza, mantenimiento, atención al cliente, hasta las ventas y postventa, nos encargamos de cada detalle para que los propietarios no tengan que preocuparse por nada. Rentamos propiedades por noche, semana o mes, dependiendo de las necesidades de nuestros clientes. Nuestro enfoque está 100% centrado en Antigua Guatemala y sus alrededores, un lugar donde la cultura, la historia y las tradiciones se entrelazan. Pero, lo que realmente nos hace únicos en este sector no es solo el servicio. En Luna Nueva hemos encontrado una manera de integrar un fuerte impacto social en nuestra gestión. Cada propiedad bajo nuestra administración, hoy alrededor de 20, no solo es un lugar para hospedar a viajeros, sino una oportunidad para aportar a la comunidad y a su desarrollo."
					},
					"SECTION3": {
						"TITLE": "Nuestro Impacto Social: Un Modelo con Tres Pilares",
						"DOTS1TITLE": "1. Educación Gratuita para Nuestra Comunidad",
						"DOTS1": "Creemos en la educación como la base para el crecimiento. Por eso, en Luna Nueva ofrecemos cursos gratuitos en línea a todo nuestro equipo, sus familiares y amigos. Actualmente impartimos cursos de inglés, y próximamente estaremos lanzando más opciones que ayudarán al desarrollo personal y profesional de nuestra comunidad. No solo trabajamos con los mejores, sino que también invertimos en su crecimiento.",
						"DOTS2TITLE": "2. Capacitación para Ofrecer Desayunos Tradicionales",
						"DOTS2": "Antigua Guatemala tiene una riqueza cultural que merece ser compartida. Por eso, hemos capacitado a nuestro equipo de limpieza en algunas de nuestras propiedades para que puedan ofrecer desayunos tradicionales guatemaltecos a nuestros huéspedes. En estas propiedades, los viajeros disfrutan de una experiencia completa al saborear recetas locales, conectándose con la cultura guatemalteca desde la primera comida del día. Esta capacitación no solo eleva la experiencia del huésped, sino que también brinda nuevas habilidades y oportunidades a nuestro equipo.",
						"DOTS3TITLE": "3. Apoyo al Arte Local: Espacios para Artistas",
						"DOTS3": "En nuestras propiedades, hemos designado un espacio especial para exhibir obras de artistas locales. Creemos que el arte es una forma poderosa de conectar a las personas con la cultura, y queremos darles a los creadores guatemaltecos la visibilidad que merecen. Al integrar piezas de arte en nuestros espacios, no solo decoramos las propiedades, sino que también brindamos un escenario para que los artistas locales compartan su talento con el mundo."
					},
					"SECTION4": {
						"TITLE": "Más que Property Management: Somos una Comunidad",
						"TEXT1": "En Luna Nueva, entendemos que la administración de propiedades no se trata solo de números y reservas, sino de las personas detrás de cada propiedad, cada huésped, y cada miembro de nuestro equipo. Este enfoque nos ha permitido crecer de manera sostenida y generar un impacto positivo en nuestra querida Antigua Guatemala. Si eres propietario y estás buscando un servicio de renta a corto plazo que no sea el típico, te invito a ser parte de Luna Nueva. Te prometo que no solo cuidaremos de tu propiedad como si fuera nuestra, sino que también contribuirás a algo más grande: el desarrollo de nuestra comunidad, el apoyo al arte local, y el empoderamiento de personas que trabajan cada día para ofrecer lo mejor de sí mismas. Gracias por ser parte de esta historia.",
						"TEXT2": "Gracias por ser parte de esta historia",
						"TEXT3": "- Jharan"
					}
				},
				"ARTICLE10": {
					"DATEPOST": "Published on January 13, 2025",
					"SECTION1": {
						"TITLE": "Luna Nueva arrives in Miami: A new chapter",
						"TEXT1": "At Luna Nueva, we have always believed that every project, property, and service has the potential to ",
						"TEXT2": "connect people, cultures, and dreams. ",
						"TEXT3": "Since our beginnings in Antigua Guatemala, we have been dedicated to creating more than just a real estate and hospitality company; we have built a community that shares our vision of social impact, personalized service, and love for what we do. Today, with much excitement, we want to share news that marks a new chapter in our history: Luna Nueva is opening its doors in Miami!",
					},
					"SECTION2": {
						"TEXT1": "This new chapter not only represents physical growth but also an expansion of our mission. ",
						"TEXT2": "Miami, with its vibrant mix of cultures and opportunities, ",
						"TEXT3": "is the perfect stage to bring our philosophy to new communities. We take this step with the same essence that characterizes us, but with the excitement of exploring new frontiers."
					},
					"SECTION3": {
						"TITLE": "Purpose-driven leadership: Angela Ascui",
						"TEXT1": "For this new adventure, we couldn't be in better hands. Angela Ascui, our friend and now leader of Luna Nueva in Miami, will guide this exciting journey. Angela shares our vision of service and commitment. With her leadership, we know that we can replicate in Miami the same passion and dedication we have put into each project in Antigua Guatemala.",
						"TEXT2": "Angela has energetically joined to lead this new chapter, seeking to create authentic connections and provide a service that makes our clients feel at home, no matter where they are."
					},
					"SECTION4": {
						"TITLE": "With the same essence",
						"TEXT1": "Even as we expand, our heart remains in Antigua Guatemala. We are excited to bring with us one of our most important pillars: ",
						"TEXT2": "a commitment to free education. ",
						"TEXT3": "In Miami, we will continue to offer online learning programs for our team and their families because we believe that knowledge is the key to transforming lives and building a future full of possibilities."
					},
					"SECTION5": {
						"TITLE": "Let's dream together",
						"TEXT1": "We know that this step would not be possible without the trust and support of each person who has been part of our community in Antigua. You have inspired us to dream bigger and to take the spirit of Luna Nueva further."
					},
					"SECTION6": {
						"TITLE": "We are eager to share this new chapter with you, whether from the beautiful cobbled streets of Antigua or the vibrant avenues of Miami.",
						"TEXT1": "If you ever need support, whether here or there, know that we will be there for you, ready to help you write your own story.",
						"TEXT2": "With gratitude",
						"TEXT3": "Jharan Marroquin"
					}
				},
				"TEXTBACK": "Back to blog",
				"TOOLTIPFB": "Share in Facebook",
				"TOOLTIPWA": "Share in WhatsApp",
				"TOOLTIPCP": "Copy link",
				"MEDIAMSG": "Share"
			}
		},
		"dialogLoginRegistSession": {
			"textForLogin": "To save properties in your favorites list, it is necessary that you previously log in or register",
			"textRegister": "Sign up",
			"textSignupOrLogin": "Sign in or sign up"
		},
		"servicesFormDialog": {
			"buttonText": "Close",
			"textHeader": "The form has been sent successfully",
			"textBody": "We will contact you shortly to attend to your request and answer all your questions.",
			"buttonAccept": "Accept"
		},
		"contactPageForm": {
			"textErrorOccur": "An error occurred",
			"textErrorExplanation": "Unexpected error. Please try again",
			"textContactUs": "Contact us"
		},
		"contactSellerPage": {
			"textContactSeller": "Contact the seller",
			"textMessage": "Hello, is this still available?"
		}
	}
}
